import { isArray } from './guards/isArray';

export function deepExtract<T = unknown>(
  value: unknown,
  extract:
    | ((
        value: unknown,
        key: string | number | undefined,
        parents: unknown[],
        stopRecursion: () => void
      ) => value is T)
    | ((
        value: unknown,
        key: string | number | undefined,
        parents: unknown[],
        stopRecursion: () => void
      ) => boolean),
  internalRecursionState: {
    shouldStop: boolean;
    recursionCache: unknown[];
  } = {
    shouldStop: false,
    recursionCache: []
  },
  /** keep track of the parent chain */
  parents: unknown[] = [],
  key?: string | number | undefined
): T[] {
  const values = [];

  if (
    extract(value, key, parents, () => {
      internalRecursionState.shouldStop = true;
    })
  ) {
    values.push(value);
  }

  if (internalRecursionState.shouldStop) {
    return [];
  }

  if (isArray(value)) {
    value.forEach((val, index) => {
      if (canExtract(val, internalRecursionState.recursionCache)) {
        internalRecursionState.recursionCache.push(val);
        values.push(
          ...deepExtract(
            val,
            extract,
            internalRecursionState,
            [...parents, value],
            index
          )
        );
      }
    });
  } else if (value && typeof value === 'object') {
    for (const key in value) {
      const val = value[key as keyof typeof value];

      if (canExtract(val, internalRecursionState.recursionCache)) {
        internalRecursionState.recursionCache.push(val);
        values.push(
          ...deepExtract(
            val,
            extract,
            internalRecursionState,
            [...parents, value],
            key
          )
        );
      }
    }
  }

  return values as T[];
}

function canExtract(value: unknown, recursionCache: unknown[]) {
  return typeof value === 'object' && value
    ? !recursionCache.includes(value)
    : true;
}
